@import "src/layout/sass/media-queries";

.exception-body {
	padding: 0;
	margin: 0;
	min-height: 100%;

	.exception-panel {
		display: none;
	}

	.exception-content {
		position: fixed;
		padding: 80px 0;
		margin: 0 auto;
		width: 100%;
		height: 100%;
		text-align: center;

		img {
			display: inline-block;
			margin-bottom: 160px;
			width: 200px;
		}

		h1 {
			font-size: 28px;
			font-weight: 500;
			margin: $panelsPadding 0;
			color: $textColor;

			.exception-name {
				color: $textSecondaryColor;
			}
		}

		p {
			font-size: 17px;
			color: $textSecondaryColor;
			margin: 0 0 32px 0;
		}
	}
}

@include media-breakpoint-above(md) {
	.exception-body {
		.exception-panel {
			display: block;
			position: fixed;
			background-color: #ffffff;
			height: 200%;
			width: 100%;
			left: -50%;
			top: -50%;
			@include opacity(0.95);
			@include border-radius(50%);
		}

		.exception-content {
			text-align: left;
			position: fixed;
			padding: 80px 0 0 80px;
		}

		&.error {
			background: url("../../images/pages/bg-error.jpg");
			background-size: cover;
		}

		&.notfound {
			background: url("../../images/pages/bg-notfound.jpg");
			background-size: cover;
		}

		&.access-denied {
			background: url("../../images/pages/bg-access.jpg");
			background-size: cover;
		}
	}
}
