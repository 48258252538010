@import "src/layout/sass/_mixins";
@import "../../../../node_modules/@fortawesome/fontawesome-pro/scss/variables";

/* Add your customizations of the theme here */

/* Dialog title */
body .p-dialog .p-dialog-titlebar .p-dialog-title {
	font-size: 24px;
}

/* Tabs */
body .p-tabmenu .p-tabmenu-nav {
	padding: $panelsPadding 0px 0px $panelsPadding;
	
	.p-tabmenuitem {
		box-sizing: border-box;
		border: 0px;
		top: 0px;
		margin: 0px;

		&.p-highlight .p-menuitem-link {
			box-shadow: inset 0px 2px 0px 0px $primaryColor;
		}

		.p-menuitem-link {
			padding: 12px;
			border-radius: 0px;
		}
	}
	.p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
		background-color: $primaryColor;
		border: 0px;
		span {
			color: white;
			h2 {
				color: white;
			}
		}
	}
}

// Override tab text colors
.page-header-slim .p-tabmenu {
	margin-left: 0px;
	.p-tabmenu-nav {
		padding-top: 5px; // Reduce padding
	}
}

.p-tabview,
.p-tabmenu {
	margin-left: -$panelsPadding;

	li {
		&:not(.p-tabview-selected):not(.p-highlight) {
			span {
				color: $foregroundPrimaryLighter;
			}
		}

		&.p-tabview-selected,
		&.p-highlight {
			span {
				color: $primaryColor;
			}
			.p-menuitem-link {
				border-top: 2px solid $primaryColor;
			}
		}
	}

	.p-tabmenu-nav {
		background-color: transparent !important;
		.p-tabmenuitem:not(.p-highlight):not(.p-disabled) a:focus {
			box-shadow: none !important;
		}
	}
}

body .p-megamenu {
	padding: 0px !important;
	border: none !important;
	
	.p-megamenu-root-list {
		padding: $panelsPadding 0px 0px 0px;
		background-color: $backgroundPrimaryLighter;

		.p-megamenu-panel {
			background-color: $backgroundPrimaryLighter;
		}
		.p-menuitem {
			box-sizing: border-box;
			border: 0px;
			top: 0px;
			margin: 0px;
			
			&.p-highlight .p-menuitem-link {
				box-shadow: inset 0px 2px 0px 0px $primaryColor;
			}

			.p-menuitem-link {
				color: $foregroundPrimaryLight !important;
				padding: 12px 12px 5px 12px;
				border-radius: 0px;
				
				span {
					color: $foregroundPrimaryLight;
					h2 {
						color: $foregroundPrimaryLight;
					}
				}
				
				&:focus {
					outline: none !important;
					outline-offset: none !important;
					@include shadow(none);
				}
			}

			&:not(.p-highlight):not(.p-disabled):not(.p-menuitem-active):not(.active):hover {
				.p-menuitem-link {
					background-color: $primaryColor;
					border-top: 2px;

					span {
						color: white;
						h2 {
							color: white;
						}
					}

					.p-menuitem-text {
						color: $foregroundOverAccent;
					}

					.p-menuitem-icon {
						color: $foregroundOverAccent;
					}
				}
			}
			&.p-menuitem-active {
				background-color: $primaryColor;
				border-top: 2px;
				
				.p-menuitem-link {
					span {
						color: white;
						h2 {
							color: white;
						}
					}
				}
				.p-submenu-icon {
					color: white;
				}
			}
			
			&.active {
				> .p-menuitem-link {
					background-color: $backgroundPrimary;
					border-top: 2px solid $accentPrimary;
					
					span {
						color: $accentPrimary;
						h2 {
							color: $accentPrimary;
						}
					}
				}
			}
		}

		.p-megamenu-submenu {
			margin-bottom: -8px;
			margin: 0;
			padding: 0;
			list-style: none;
			width: 12.5em;

			.p-megamenu-submenu-header {
				margin-top: 8px;
				border-bottom: 1px solid $backgroundPrimary;
				padding-left: 0.65em;
				padding-bottom: 2px;
				color: $foregroundPrimary;
				font-weight: 600;
    			
				&.empty {
					margin: 0;
					padding: 0;
					border-bottom: none;
					display: none;
				}
			}
			
			.p-menuitem-link {				
				padding: 12px;

				span {
					color: $accentPrimary !important;
					
					h2 {
						color: $accentPrimary !important;
					}

					span {
						color: $foregroundOverAccent !important;
					}
				}

				&:hover {
					background-color: $statusNeutralLighter !important;
					
				}
			}
			.p-menuitem {
				&.active {
					> .p-menuitem-link {
						background-color: $statusNeutralLighter;
						border-top: 0px;
						
						span {
							color: $foregroundOverAccent;
							h2 {
								color: $foregroundOverAccent;
							}
						}
					}
				}
			}
		}
	}
}

/* Card */
body .p-card {
	background-color: $backgroundPrimaryLighter;
	border: 1px solid $borderPrimaryLight;
	border-radius: $borderRadius;
	box-shadow: 0 2px 3px -1px rgba(128, 128, 128, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	
	.p-card-body {
		padding: $panelsPadding;
		// datatable toolbar menus need 'overflow' to be set to 'initial' value in order to show
		overflow: initial; //hidden;
	}
}

body .p-datatable .p-datatable-tfoot > tr > td {
	border: none;
}

/* Panel Menu*/
.p-panelmenu {

	.p-panelmenu-header{
		// border: 1px solid $borderPrimary;
		// border-radius: 5px;
		width: 210px;
	}

	.p-panelmenu-icon {
		margin-right: 5px;
	}

	.p-menuitem-icon {
		margin-right: 5px;
	}
}


/* Multi Select */
body .p-multiselect {
	border: $borderDark !important;
}

/* Tables */
body .p-table-reorderablerow-handle {
	@include icon-content($fa-var-grip-lines);
}
