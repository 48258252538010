@import "../_colors";

/* Add your variable customizations of layout here */
$bodyBgColor: $backgroundPrimary;
$logoBgColor: transparent;
$menuBgGradient: linear-gradient(180deg, $menuBgColorLight 0%, $menuBgColor 30%);
$menuBgRightGradient: linear-gradient(270deg, #000000 5%, #000000 100%);
$topbarBgColor: $primaryColor;
$topbarIconColor: #ffffff;
$topbarIconHoverBgColor: $accentPrimaryLight;
$lightMenuitemActiveTextColor: $primaryColor;
$darkMenuitemActiveTextColor: $primaryColor;
$horizontalMenuitemActiveBgColor: $primaryColor;
$horizontalMenuitemActiveTextColor: #ffffff;
$badgeBgColor: $primaryColor;
$badgeTextColor: #ffffff;
