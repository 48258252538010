// Fund Manager Design System

// Blends a semiopaque rgba color with a color, avoiding transparencies over unknown backgrounds;
@function alphablend($color, $background: rgb(255, 255, 255)) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    @return mix($opaque, $background, $percent);
}

// Fund Manager colors

// Fund Manager colors - Accent - For foreground and backgrounds of important controls to be highlighted
$accentPrimary: var(--accentPrimary, #2c6ce6);
$accentPrimaryLight: var(--accentPrimaryLight, #d4e1fa); // Select - same as: alphablend(rgba($accentPrimary, 0.2), $backgroundPrimaryLighter);
$accentPrimaryLighter: var(--accentPrimaryLighter, #e9f0fc); // Hover - same as: alphablend(rgba($accentPrimary, 0.1), $backgroundPrimaryLighter);
$accentSecondary: var(--accentSecondary);
$accentSecondaryLight: var(--accentSecondaryLight); // Modified rows - same as: alphablend(rgba($accentSecondary, 0.2), $backgroundPrimaryLighter);
$accentSecondaryLighter: var(--accentSecondaryLighter); // Alternative title background (e.g. AdvancedFilterPanel)

// Fund Manager colors - Background - For background of pages and controls
$backgroundPrimary: var(--backgroundPrimary);
$backgroundPrimaryLight: var(--backgroundPrimaryLight);
$backgroundPrimaryLighter: var(--backgroundPrimaryLighter);

// Fund Manager colors - Foreground - For foreground of pages and controls
$foregroundPrimary: var(--foregroundPrimary);
$foregroundPrimaryLight: var(--foregroundPrimaryLight);
$foregroundPrimaryLighter: var(--foregroundPrimaryLighter);
$foregroundOverAccent: var(--foregroundOverAccent);

// Fund Manager colors - Border - For borders of controls and surfaces in general cases
$borderPrimary: var(--borderPrimary);
$borderPrimaryLight: var(--borderPrimaryLight);
$borderPrimaryLighter: var(--borderPrimaryLighter);

// Fund Manager colors - Status - To apply on specific situations where the meaning of the color encodes a specific status
$statusNegative: var(--statusNegative);
$statusNegativeLighter: var(--statusNegativeLighter);
$statusNegativeLighterBg: var(--statusNegativeLighterBg);   // Status color version with no transparancy
$statusAttention: var(--statusAttention);
$statusAttentionLighter: var(--statusAttentionLighter);
$statusAttentionLighterBg: var(--statusAttentionLighterBg);   // Status color version with no transparancy
$statusPositive: var(--statusPositive);
$statusPositiveLighter: var(--statusPositiveLighter);
$statusPositiveLighterBg: var(--statusPositiveLighterBg);   // Status color version with no transparancy
$statusNeutral: var(--statusNeutral);
$statusNeutralLighter: var(--statusNeutralLighter);
$statusInactive: var(--statusInactive);
$statusInactiveLighter: var(--statusInactiveLighter);
$statusDelegation: var(--statusDelegation);
$statusHistory: var(--statusHistory);
$statusHistoryLight: var(--statusHistoryLight);     // Same as alphablend(rgba(<color>, 0.2), <bgcolor>);
$statusHistoryLighter: var(--statusHistoryLighter); // Same as alphablend(rgba(<color>, 0.1), <bgcolor>);
$statusBreadcrumb: var(--statusBreadcrumb);

// Global Prime React variables
$textColor: $foregroundPrimary;
$textSecondaryColor: $foregroundPrimaryLight;
$textInverseColor: $foregroundOverAccent;
$textActionColor: $statusNegative;
$primaryColor: $accentPrimary;
$highlightColor: $accentPrimaryLight;
$inputFocusBorderColor: $accentPrimary;
$menuBgColor: #090909;
$menuBgColorLight: #262626;
$menuitemTextColor: #d9d9d9;
$menuHamburgerBg: var(--menuHamburgerBg);
$menuHamburgerBgHover: var(--menuHamburgerBgHover);

// Other Colors
$backgroundMask: var(--backgroundMask);
$initialsBgColor: #e3008c;
$statusDelegationBorder: #73285e;
$searchFilterBgColor: var(--searchFilterBgColor);
$searchFilterTextColor: var(--searchFilterTextColor);