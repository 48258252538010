@import "colors";
// Fund Manager Design System

// Global Prime React variables
$fontFamily: "Noto Sans SemiCondensed", sans-serif;
$iconFontFamily: "Font Awesome 5 Pro";
$fontSize: 14px;
$fontSizeTitle: 20px;
$borderRadius: 3px;
$transitionDuration: 0.3s;

// Fund Manager variables
$borderDark: 1px solid $borderPrimary;
$borderLight: 1px solid $borderPrimaryLight;
$borderLighter: 1px solid $borderPrimaryLighter;
$panelsPadding: 15px; // same as bootstrap row margin 
$inputHeight: 34px;
$inputDisabledOpacity: 0.5;
$focusShadow: 0 0 0 1px $inputFocusBorderColor;

// Bootstrap overrides
$spacer: $panelsPadding; // override bootstrap default = $spacer: 1rem !default; = 1rem = 14px = font-size of the root element 