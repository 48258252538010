body {
    .p-component-overlay {
        background-color: $maskBgColor;
    }

    .p-overlaypanel {
        background-color: $panelContentBgColor;
        color: $panelContentTextColor;
        padding: 0;
        border: $overlayContentBorder;
        @include border-radius($borderRadius);
        @include shadow($overlayContainerShadow);

        .p-overlaypanel-content {
            padding: $panelContentPadding;
        }

        .p-overlaypanel-close {
            background-color: $overlayPanelCloseIconBgColor;
            color: $overlayPanelCloseIconColor;
            
            width: 1.538em;
            height: 1.538em;
            line-height: 1.538em;
            text-align: center;
            position: absolute;
            top: -1 * 1.538em / 2;
            right: -1 * 1.538em / 2;
            @include border-radius(50%);
            @include transition(background-color $transitionDuration, box-shadow $transitionDuration);
            
            &:hover {
                background-color: $overlayPanelCloseIconHoverBgColor;
                color: $overlayPanelCloseIconHoverColor;
            }

            > span {
                font-size: 0.8em;
                margin-top: 1px;
            }
        }
    }

    .p-dialog {
        padding:  1em 2em;
        background-color: $panelContentBgColor;
        @include shadow($overlayContainerShadow);
        
        .p-dialog-header {
            border: 0 none;
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            padding: $dialogHeaderPadding;
            font-weight: $panelHeaderFontWeight;
            
            .p-dialog-title {
                margin-right: 10px;
                float: none;
                font-size: 20px;
                font-weight: $panelHeaderFontWeight;
            }
            
            .p-dialog-header-icon {
                color: $textSecondaryColor;
                border: 0 none;
                padding: 0;
                margin-left: $inlineSpacing;
                @include transition(color $transitionDuration, box-shadow $transitionDuration);
                
                &:hover {
                    color: $textColor;
                }
            }
        }
        
        .p-dialog-content {
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            border: 0 none;
            padding: .5em;
        }
        
        .p-dialog-footer {
            background-color: $panelFooterBgColor;
            color: $panelFooterTextColor;
            padding: $panelFooterPadding;
            margin: 0;
            text-align: right;
            
            button {
                margin: 0 $inlineSpacing 0 0;
                width: auto;
            }
        }
    }

    .p-sidebar {
        background-color: $panelContentBgColor;
        color: $panelContentTextColor;
        padding: $panelContentPadding;
        border: $overlayContentBorder;
        @include shadow($overlayContainerShadow);

        .p-sidebar-close {
            color: $textSecondaryColor;

            &:hover {
                color: $textColor;
            }
        }
    }

    .p-tooltip {
        .p-tooltip-text {
            background-color: $tooltipBgColor;
            color: $tooltipTextColor;
            padding: $tooltipPadding;
            @include shadow($overlayContainerShadow);
            @include border-radius($borderRadius); 
        }
        
        &.p-tooltip-right {
            .p-tooltip-arrow {
                border-right-color: $tooltipBgColor;
            }
        }
        
        &.p-tooltip-left {
            .p-tooltip-arrow {
                border-left-color: $tooltipBgColor;
            }
        }
        
        &.p-tooltip-top {
            .p-tooltip-arrow {
                border-top-color: $tooltipBgColor;
            }
        }
        
        &.p-tooltip-bottom {
            .p-tooltip-arrow {
                border-bottom-color: $tooltipBgColor;
            }
        }
    }

    .p-lightbox {
        @include shadow($overlayContainerShadow);
        
        .p-lightbox-caption {
            border: $panelHeaderBorder;
            background-color: $panelHeaderBgColor;
            color: $panelHeaderTextColor;
            padding: $panelHeaderPadding;
            font-weight: $panelHeaderFontWeight;
            
            
            .p-lightbox-caption-text {
                color: $panelHeaderTextColor;
                margin: 0;
            }
            
            .p-lightbox-close {
                padding: 0;
                color: $panelHeaderIconColor;
                @include transition(color $transitionDuration);
                
                &:hover {
                    color: $panelHeaderIconHoverColor;
                }
            }
        }

        .p-lightbox-content-wrapper {
            overflow: hidden;
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            border: 0 none;
            padding: 0;
            @include border-radius(0);
                      
            .p-lightbox-nav-left, .p-lightbox-nav-right {
                @include transition(all $transitionDuration);
                font-size: $lightBoxNavIconFontSize;
                color: $lightBoxNavIconColor;
                margin-top: -.5em;

                &:hover {
                    @include scale(1.2);
                }
            }
            
            .p-lightbox-content.p-lightbox-loading ~ a {
                display: none;
            }
        }
    }


    //  edit the styles for dialog
    div.p-dialog-header {
        font-size: 20px;
        color: #212121;
    }

    .p-dialog-header {
        .p-dialog-title {
            padding-right: 1rem;
        }
    }

    div.p-dialog-content {
        color: #696969;
        i {
            font-size: 2rem;
        }
    }   

    div.p-dialog-footer {
        background-color: transparent;
    }

}