@import "src/layout/sass/_variables";

/* Add your customizations of layout here */
.layout-wrapper {
    height: inherit;
    .layout-main:not(.layout-addin):not(.layout-tasksbaron) {
        height: calc(100% - 60px);
    }
    .layout-main.layout-tasksbaron {
        height: calc(100% - 108px);
    }
    .layout-main.layout-addin {
        height: 100%;
        .layout-content {
            height: 100%;
            background-color: $backgroundPrimaryLighter;
        }
    }
    .layout-main {
        background-color: $backgroundPrimaryLighter;
        display: flex;
        flex-flow: column nowrap;
        padding-top: 0px;
        overflow-y: auto;
        .layout-content {
            flex: 1 1 auto;
            display: flex;
            flex-flow: column nowrap;
            padding: $panelsPadding;
            background-color: $backgroundPrimary;
        }
    }

    &.layout-overlay {
        .layout-main {
            width: 100%;
        }
    }
    
    .p-toolbar {
        background-color: transparent;
        border: 0px;
        margin: 0px;
        padding: 0px;
        border-radius: 0px;
        .p-toolbar-group-left,
        .p-toolbar-group-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            min-height: $inputHeight;
        }
        .p-toolbar-group-left {
            justify-content: flex-start;
            h2 + * {
                margin-left: $panelsPadding;
            }
        }
        .p-toolbar-group-right {
            justify-content: flex-end;
        }
    }
    .route-bar .route-bar-breadcrumb {
        padding: 2px $panelsPadding 0px $panelsPadding;
    }

    .layout-root-menuitem {
        a {
            color: $menuitemTextColor;
            .layout-menuitem-text {
                color: $menuitemTextColor !important;
            }
            i {
                margin-right: 6px;
            }
        }
    }

    // cards
    .p-card-body {
        height: 100%
    }
    
    .p-card-content {
        height: 100%;
    }

    // primereact carousel styles
    .p-carousel {
        div {
            div {
                div {
                    .p-carousel-item {
                        flex-grow: 1;
                        flex-shrink: 0;
                        flex-basis: 33.3333%;
                    }
                }
            }
        }
        .p-carousel-indicators {
            padding: 1rem;
            justify-content: center;
            flex-wrap: wrap;
            .p-carousel-indicator {
                margin-right: 0.5rem;
                margin-bottom: 0.5rem;
                button {
                    background-color: #ced4da;
                    width: 2rem;
                    height: 0.5rem;
                    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
                    border-radius: 0;
                    border: none
                }
                &.p-highlight {
                    button {
                        background-color: #6366F1;
                    }
                }
            }
        }
        .p-carousel-prev, .p-carousel-next {
            width: 2.5rem;
            height: 2.5rem;
            color: #6c757d;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            margin: 0.5rem;

            :hover {
                background: #ced4da;
                border-radius: 50%;
                padding: 0.5rem;
                transition: background-color 0.1s;
            } 

            .pi {
                font-size: 1.5rem;
            }
        }
    }

    // scroll image panel
    .p-datascroller-inline {
        .p-datascroller-content {
            border: 0;
        }
    }

    .p-fileupload {
        .p-fileupload-content {
            border: 0;
        }
    }

    // multiselect
    .p-multiselect-item, .gx-multiselect-item-selected {
        background-color: $primaryColor;
        color: #fff;
        max-width: 250px;
        label {
            // padding: 6px 0px 5px 0px !important;
            font-weight: normal;
            margin: 0px;
        }
    }
    .gx-multiselect-item-selected {
        padding: 4px 8px;
        margin: 1px .286em 1px 0;
        display: inline-block;
        vertical-align: middle;
        height: 1.857em;
        border-radius: 3px;
    }

    .p-fileupload-files {
        display: inline-block;
    }
}

.button-borderless {
    background: none !important;
    border: 0px!important;
    &:not(.p-disabled) {
        color: $textColor;
        span.far {
            color: $textSecondaryColor;
        }
    }
    &.p-disabled {
        color: $foregroundPrimaryLighter !important;
        opacity: 1;
    }
    .p-button-icon-left {
        left: 0px !important;
    }
    .p-button-text {
        padding-left: 22px !important;
        padding-right: 0px !important;
    }
    &:enabled:hover,
    &:focus {
        background: none;
        color: $accentPrimaryLight !important;
        span.far {
            color: $accentPrimaryLight;
        }
    }
    &:focus {
        border: 0px !important;
        box-shadow: none !important;
    }
    &:active:not(.p-disabled) {
        background: none;
        color: $textSecondaryColor;
        span.far {
            color: $textSecondaryColor;
        }
    }
}

// display the date-picking component in sidebars
.react-datepicker-popper {
    z-index: 1090 !important;
}

.p-datepicker-title {
    white-space: nowrap;
}








