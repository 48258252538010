@import "src/layout/sass/_variables";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/variables";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/fixed-width";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/mixins";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/core";

%gx-icon {
	@extend .far;
	@extend .fa-fw;
	font-weight: 400; // 400-Regular; 900-Solid;
	font-family: $iconFontFamily;
}

%gx-icon-light {
	@extend .fal;
	@extend .fa-fw;
	font-weight: 100; // 400-Regular; 900-Solid;
	font-family: $iconFontFamily;
}

@mixin icon-content($icon: null, $color: null) {
	@extend %gx-icon;
    color: $color;
    &:before {
        content: fa-content($icon);
    }
}
