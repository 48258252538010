@import "src/layout/sass/media-queries";
@import "src/layout/sass/_mixins";
@import "src/layout/sass/_variables";
@import "../../../../node_modules/@fortawesome/fontawesome-pro/scss/variables";

body {
    .p-messages {
        margin: $messagesMargin;
        @include border-radius($borderRadius);

        .p-messages-wrapper {
            padding: $messagesPadding;
        }
        
        &.p-messages-info {
            background-color: $infoMessageBgColor;
            border: $infoMessageBorder;
            color: $infoMessageTextColor;
            
            .p-messages-icon {
                color: $infoMessageIconColor;
            }
            
            .p-messages-close {
                color:  $infoMessageIconColor;
            }
        }

        &.p-messages-success {
            background-color: $successMessageBgColor;
            border: $successMessageBorder;
            color: $successMessageTextColor;
            
            .p-messages-icon {
                color: $successMessageIconColor;
            }
            
            .p-messages-close {
                color:  $successMessageIconColor;
            }
        }

        &.p-messages-warn {
            background-color: $warnMessageBgColor;
            border: $warnMessageBorder;
            color: $warnMessageTextColor;
            
            .p-messages-icon {
                color: $warnMessageIconColor;
            }
            
            .p-messages-close {
                color:  $warnMessageIconColor;
            }
        }

        &.p-messages-error {
            background-color: $errorMessageBgColor;
            border: $errorMessageBorder;
            color: $errorMessageTextColor;
            
            .p-messages-icon {
                color: $errorMessageIconColor;
            }
            
            .p-messages-close {
                color:  $errorMessageIconColor;
            }
        }
        
        .p-messages-close {
            top: .25em;
            right: .5em;
            font-size: $messageCloseIconFontSize;
            @include border-radius($borderRadius);
            @include transition(box-shadow $transitionDuration);

            &:focus {
                @include focused();
            }
        }

        .p-messages-icon {
            font-size: $messagesIconFontSize;
        }
    }

    .p-inline-message,
    .p-message {
        padding: $messagePadding;
        margin: $messageMargin;
        @include border-radius($borderRadius);
        
        &.p-inline-message-info,
        &.p-message-info {
            background-color: $infoMessageBgColor;
            border: $infoMessageBorder;
            color: $infoMessageTextColor;
            
            .p-inline-message-icon,
            .p-message-icon {
                color: $infoMessageIconColor;
            }
        }

        &.p-inline-message-success,
        &.p-message-success {
            background-color: $successMessageBgColor;
            border: $successMessageBorder;
            color: $successMessageTextColor;
            
            .p-inline-message-icon,
            .p-message-icon {
                color: $successMessageIconColor;
            }
        }

        &.p-inline-message-warn,
        &.p-message-warn {
            background-color: $warnMessageBgColor;
            border: $warnMessageBorder;
            color: $warnMessageTextColor;
            
            .p-inline-message-icon,
            .p-message-icon {
                color: $warnMessageIconColor;
            }
        }

        &.p-inline-message-error,
        &.p-message-error {
            background-color: $errorMessageBgColor;
            border: $errorMessageBorder;
            color: $errorMessageTextColor;
            
            .p-inline-message-icon,
            .p-message-icon {
                color: $errorMessageIconColor;
            }
        }

        .p-inline-message-icon,
        .p-message-icon {
            font-size: $messageIconFontSize;
        }

        .p-inline-message-text,
        .p-message-text {
            font-size: $messageTextFontSize;
            margin-left: 8px;
        }
    }
        
    .p-toast {
        top: $toastTopLocation;
        @include opacity($toastOpacity);

        .p-toast-message {
            margin: $toastMargin;
            @include shadow($toastShadow);
            border-radius: $toastBorderRadius;
            
            .p-toast-message-content {
                padding: $toastPadding;
                overflow: hidden;
                
                .p-toast-message-icon {
                    font-family: $iconFontFamily;
                    font-size: $toastIconFontSize;
                }
                
                .p-toast-message-text {
                    margin: $toastMessageTextMargin;
                }
    
                .p-toast-icon-close {
                    font-size: 14px;
                    position: absolute;
                    right: 12px;
                }
            }

            &.p-toast-message-info {
                background-color: $infoMessageBgColor;
                border: $infoMessageBorder;
                color: $infoMessageTextColor;
                
                .p-toast-message-icon,
                .p-toast-icon-close {
                    color: $infoMessageIconColor;
                }

                .p-toast-message-icon {
                    @include icon-content($fa-var-info-circle, $infoMessageIconColor);
                }
            }  

            &.p-toast-message-success {
                background-color: $successMessageBgColor;
                border: $successMessageBorder;
                color: $successMessageTextColor;
                
                .p-toast-message-icon,
                .p-toast-icon-close {
                    color: $successMessageIconColor;
                }

                .p-toast-message-icon {
                    @include icon-content($fa-var-check, $successMessageIconColor);
                }
            }  
            
            &.p-toast-message-warn {
                background-color: $warnMessageBgColor;
                border: $warnMessageBorder;
                color: $warnMessageTextColor;
                
                .p-toast-message-icon,
                .p-toast-icon-close {
                    color: $warnMessageIconColor;
                }

                .p-toast-message-icon {
                    @include icon-content($fa-var-exclamation-triangle, $warnMessageIconColor);
                }
            }
            
            &.p-toast-message-error {
                background-color: $errorMessageBgColor;
                border: $errorMessageBorder;
                color: $errorMessageTextColor;
                
                .p-toast-message-icon,
                .p-toast-icon-close {
                    color: $errorMessageIconColor;
                }

                .p-toast-message-icon {
                    @include icon-content($fa-var-exclamation-circle, $errorMessageIconColor);
                }
            }
        }
    }
    
    @include media-breakpoint-below(md) {
        .p-toast-message {
            border-radius: 0!important;
        }
    }
}