@import "colors";

// Color per status (normal, lighter)
$status-colors: (
    negative: (
        $statusNegative,
        $statusNegativeLighter,
    ),
    attention: (
        $statusAttention,
        $statusAttentionLighter,
    ),
    positive: (
        $statusPositive,
        $statusPositiveLighter,
    ),
    neutral: (
        $statusNeutral,
        $statusNeutralLighter,
    ),
    inactive: (
        $statusInactive,
        $statusInactiveLighter,
    ),
    delegation: (
        $statusDelegation,
        $statusDelegation,
    ),
    history: (
        $statusHistory,
        $statusHistoryLighter,
    )
);

// Generate classes (e.g. status-negative-color)
@each $name, $prop in $status-colors {
    .status-#{$name}-color {
        color: nth($prop, 1) !important;
    }
    // If needed in the future:
    // .status-#{$name}-lighter-color {
    //     color: nth($prop, 2);
    // }
}
