.red {
	color: red !important;
}

.lightred--border {
	border-color: lightcoral !important;
}

.hover {
	&.lightred--hover:hover {
		background-color: #fff5f5 !important;
	}
}

