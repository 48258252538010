@import "../_variables";

$primaryDarkColor: $accentPrimary;
$primaryDarkerColor: $accentSecondary;
$primaryTextColor: $backgroundPrimaryLighter;
$primaryLighterColor: $accentPrimaryLighter;
$accentColor: $primaryColor;
$accentColorText: $foregroundOverAccent;
$accentDarkerColor: $primaryDarkColor;

$containerHeaderIconColor: #bdbdbd;
$containerHeaderTextColor: #ffffff;
$containerHeaderBgColor: #212121;
$containerHeaderBorder: 1px solid #212121;
$containerHeaderIconHoverColor: #ffffff;

$clickableContainerHeaderBgColor: #212121;
$clickableContainerHeaderBorder: 1px solid #212121;
$clickableContainerHeaderTextColor: #ffffff;
$clickableContainerHeaderIconColor: #bdbdbd;

$clickableContainerHeaderHoverBgColor: #373737;
$clickableContainerHeaderHoverBorder: 1px solid #373737;
$clickableContainerHeaderHoverTextColor: #ffffff;
$clickableContainerHeaderHoverIconColor: #bdbdbd;

$clickableContainerHeaderActiveBgColor: $primaryColor;
$clickableContainerHeaderActiveBorder: 1px solid $primaryColor;
$clickableContainerHeaderActiveTextColor: $primaryTextColor;
$clickableContainerHeaderActiveIconColor: $primaryTextColor;

$clickableContainerHeaderActiveHoverBgColor: $primaryDarkColor;
$clickableContainerHeaderActiveHoverHoverBorder: 1px solid $primaryDarkColor;
$clickableContainerHeaderActiveHoverTextColor: $primaryTextColor;
$clickableContainerHeaderActiveHoverIconColor: $primaryTextColor;

$primeIconFontSize: 1.143em;

//anchors
$linkColor: $primaryColor;
$linkHoverColor: $primaryDarkColor;
$linkActiveColor: $primaryDarkerColor;

//highlight
$highlightBgColor: $accentColor;
$highlightColorText: $accentColorText;

//focus
$focusOutline: 0 none;
$focusOutlineOffset: 0;
// $focusShadow:0 0 0 1px $inputFocusBorderColor;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding: 0.429em;
$inputBgColor: $backgroundPrimaryLighter;
$inputBorder: 1px solid $borderPrimary;
$inputHoverBorderColor: #bdbdbd;
$inputFocusBorderColor: $primaryColor;
$inputErrorBorder: 1px solid #ef5350;
$inputPlaceholderTextColor: #909090;
$inputTransition: border-color $transitionDuration, box-shadow $transitionDuration;

//input groups
$inputGroupBorderColor: #e0e0e0;
$inputGroupBgColor: #eeeeee;
$inputGroupTextColor: $textColor;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListMinWidth: 12em;
$inputListBgColor: $backgroundPrimaryLighter;
$inputListPadding: 0;
$inputListItemPadding: 5px 10px;
$inputListItemBgColor: transparent;
$inputListItemTextColor: $textColor;
$inputListItemHoverBgColor: $primaryLighterColor;
$inputListItemHoverTextColor: $primaryDarkColor;
$inputListItemHighlightBgColor: $highlightBgColor;
$inputListItemHighlightTextColor: $highlightColorText;
$inputListItemBorder: 0 none;
$inputListItemMargin: 0;
$inputListItemFocusShadow: inset 0 0 0 0.2em $accentPrimaryLight;
$inputListItemTransition: background-color $transitionDuration, box-shadow $transitionDuration;
$inputListItemDividerColor: #e0e0e0;
$inputListHeaderPaddingTop: 0.429em;
$inputListHeaderPaddingLeft: 0.857em;
$inputListHeaderPaddingRight: 0.857em;
$inputListHeaderPaddingBottom: 0.429em;
$inputListHeaderMargin: 0;
$inputListHeaderBgColor: #eeeeee;
$inputListHeaderTextColor: $textColor;
$inputListHeaderBorder: 1px solid #e0e0e0;
$inputListHeaderSearchIconColor: $textSecondaryColor;
$inputListHeaderCloseIconColor: $primaryColor;
$inputListHeaderCloseIconHoverColor: $primaryDarkColor;
$inputListHeaderCloseIconTransition: color $transitionDuration;

//inputs with panels (e.g. password)
$inputContentPanelPadding: 0.857em;
$inputContentPanelBgColor: $backgroundPrimaryLighter;
$inputContentPanelTextColor: $textColor;

//static positioned input lists like listbox
$inputListBorder: 1px solid $borderPrimaryLight;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBorder: 1px solid $borderPrimaryLight;
$inputOverlayShadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

//input dropdowns (e.g. multiselect, dropdown)
$inputDropdownIconColor: $textSecondaryColor;

//button
$buttonTextOnlyPadding: 0;
$buttonWithLeftIconPadding: 4px 8px; //0.429em 0.75em;
$buttonWithRightIconPadding: 0.359em 2em 0.359em 0.75em;
$buttonIconOnlyPadding: 0.359em;
$buttonIconOnlyWidth: 2.143em;
$buttonBgColor: $primaryColor;
$buttonBorder: 1px solid $primaryColor;
$buttonTextColor: $foregroundOverAccent;
$buttonHoverBgColor: $primaryDarkColor;
$buttonHoverTextColor: $foregroundOverAccent;
$buttonHoverBorderColor: $primaryDarkColor;
$buttonActiveBgColor: $primaryDarkerColor;
$buttonActiveTextColor: $foregroundOverAccent;
$buttonActiveBorderColor: $primaryDarkerColor;
$buttonTransition: background-color $transitionDuration, box-shadow $transitionDuration;
$raisedButtonShadow: 0 3px 2px 0 rgba(0, 0, 0, 0.2);
$roundedButtonBorderRadius: 1em;

$secondaryButtonBgColor: #f5f5f5;
$secondaryButtonBorder: 1px solid #f5f5f5;
$secondaryButtonTextColor: #212121;
$secondaryButtonHoverBgColor: #e0e0e0;
$secondaryButtonHoverTextColor: #212121;
$secondaryButtonHoverBorderColor: #e0e0e0;
$secondaryButtonActiveBgColor: #bdbdbd;
$secondaryButtonActiveTextColor: #212121;
$secondaryButtonActiveBorderColor: #bdbdbd;
$secondaryButtonFocusShadow: 0 0 0 0.2em $accentPrimaryLight;

$infoButtonBgColor: #2196f3;
$infoButtonTextColor: #ffffff;
$infoButtonBorder: 1px solid #2196f3;
$infoButtonHoverBgColor: #1976d2;
$infoButtonHoverTextColor: #ffffff;
$infoButtonHoverBorderColor: #1976d2;
$infoButtonActiveBgColor: #1565c0;
$infoButtonActiveTextColor: #ffffff;
$infoButtonActiveBorderColor: #1565c0;
$infoButtonFocusShadow: 0 0 0 0.2em lighten($infoButtonBgColor, 25%);

$successButtonBgColor: #4caf50;
$successButtonTextColor: #ffffff;
$successButtonBorder: 1px solid #4caf50;
$successButtonHoverBgColor: #388e3c;
$successButtonHoverTextColor: #ffffff;
$successButtonHoverBorderColor: #388e3c;
$successButtonActiveBgColor: #2e7d32;
$successButtonActiveTextColor: #ffffff;
$successButtonActiveBorderColor: #2e7d32;
$successButtonFocusShadow: 0 0 0 0.2em lighten($successButtonBgColor, 25%);

$warningButtonBgColor: #ffc107;
$warningButtonTextColor: #000000;
$warningButtonBorder: 1px solid #ffc107;
$warningButtonHoverBgColor: #ffa000;
$warningButtonHoverTextColor: #000000;
$warningButtonHoverBorderColor: #ffa000;
$warningButtonActiveBgColor: #ff8f00;
$warningButtonActiveTextColor: #000000;
$warningButtonActiveBorderColor: #ff8f00;
$warningButtonFocusShadow: 0 0 0 0.2em lighten($warningButtonBgColor, 25%);

$dangerButtonBgColor: #fe3a3a;
$dangerButtonTextColor: #ffffff;
$dangerButtonBorder: 1px solid #eb4d4d;
$dangerButtonHoverBgColor: darken($dangerButtonBgColor, 10%);
$dangerButtonHoverTextColor: #ffffff;
$dangerButtonHoverBorderColor: $dangerButtonHoverBgColor;
$dangerButtonActiveBgColor: darken($dangerButtonBgColor, 10%);
$dangerButtonActiveTextColor: #ffffff;
$dangerButtonActiveBorderColor: lighten($dangerButtonBgColor, 10%);
$dangerButtonFocusShadow: 0 0 0 0.2em lighten($dangerButtonBgColor, 25%);

//checkbox
$checkboxWidth: 20px;
$checkboxHeight: 20px;
$checkboxActiveBorderColor: $primaryColor;
$checkboxActiveBgColor: $primaryColor;
$checkboxActiveTextColor: $primaryTextColor;
$checkboxActiveHoverBgColor: $accentPrimaryLighter;
$checkboxActiveHoverTextColor: $primaryColor;
$checkboxActiveHoverBorderColor: $primaryColor;
$checkboxTransition: background-color $transitionDuration, border-color $transitionDuration,
    box-shadow $transitionDuration;
$checkboxIconColor: #ffffff;
$checkboxSuccessBgColor: #4caf50;
$checkboxErrorBgColor: #a60104;
$checkboxMixedBgColor: #2196f3;

//radiobutton
$radiobuttonWidth: 20px;
$radiobuttonHeight: 20px;
$radiobuttonActiveBorderColor: $primaryColor;
$radiobuttonActiveBgColor: $primaryColor;
$radiobuttonActiveTextColor: $primaryTextColor;
$radiobuttonActiveHoverBgColor: $primaryDarkerColor;
$radiobuttonActiveHoverTextColor: $primaryTextColor;
$radiobuttonActiveHoverBorderColor: $primaryColor;
$radiobuttonTransition: background-color $transitionDuration, border-color $transitionDuration,
    box-shadow $transitionDuration;

//togglebutton
$toggleButtonBgColor: $backgroundPrimary;
$toggleButtonBorder: 1px solid $backgroundPrimary;
$toggleButtonTextColor: $textColor;
$toggleButtonIconColor: $textSecondaryColor;
$toggleButtonHoverBgColor: $primaryLighterColor;
$toggleButtonHoverBorderColor: $primaryLighterColor;
$toggleButtonHoverTextColor: $textColor;
$toggleButtonHoverIconColor: $primaryColor;
$toggleButtonActiveBgColor: $primaryColor;
$toggleButtonActiveBorderColor: $primaryColor;
$toggleButtonActiveTextColor: $primaryTextColor;
$toggleButtonActiveIconColor: $primaryTextColor;
$toggleButtonActiveHoverBgColor: $primaryDarkColor;
$toggleButtonActiveHoverBorderColor: $primaryDarkColor;
$toggleButtonActiveHoverTextColor: $primaryTextColor;
$toggleButtonActiveHoverIconColor: $primaryTextColor;

//inplace
$inplacePadding: $inputPadding;
$inplaceHoverBgColor: $primaryLighterColor;
$inplaceHoverTextColor: $primaryDarkerColor;
$inplaceTransition: background-color $transitionDuration;

//rating
$ratingTransition: color $transitionDuration;
$ratingCancelIconColor: #e57373;
$ratingCancelHoverIconColor: #e57373;
$ratingIconFontSize: 1.429em;
$ratingStarIconColor: $textColor;
$ratingStarIconHoverColor: $primaryDarkColor;

//slider
$sliderBgColor: #e0e0e0;
$sliderBorder: 0 none;
$sliderHeight: 0.286em;
$sliderWidth: 0.286em;
$sliderHandleWidth: 1.429em;
$sliderHandleHeight: 1.429em;
$sliderHandleBgColor: #bdbdbd;
$sliderHandleBorder: 2px solid #bdbdbd;
$sliderHandleBorderRadius: 50%;
$sliderHandleHoverBorder: 2px solid $primaryLighterColor;
$sliderHandleHoverBgColor: $primaryLighterColor;
$sliderHandleTransition: background-color $transitionDuration;
$sliderRangeBgColor: $primaryColor;

//calendar
$calendarWidth: 18.571em;
$calendarNavIconColor: $textSecondaryColor;
$calendarNavIconHoverColor: $primaryColor;
$calendarNavIconTransition: color $transitionDuration, box-shadow $transitionDuration;
$calendarPadding: 0.857em;
$calendarTableMargin: 0.857em 0 0 0;
$calendarHeaderCellPadding: 0.286em;
$calendarCellDatePadding: 0.286em;
$calendarCellDateHoverBgColor: $primaryLighterColor;
$calendarCellDateBorderRadius: $borderRadius;
$calendarCellDateSelectedBgColor: $highlightBgColor;
$calendarCellDateSelectedTextColor: $highlightColorText;
$calendarCellDateTodayBgColor: $backgroundPrimaryLighter;
$calendarCellDateTodayTextColor: $textColor;
$calendarTimePickerDivider: 1px solid #d8dae2;
$calendarTimePickerPadding: 0.857em;
$calendarTimePickerIconColor: $textColor;
$calendarTimePickerIconFontSize: 1.286em;
$calendarTimePickerTimeFontSize: 1.286em;
$calendarTimePickerIconHoverColor: $primaryColor;
$calendarButtonBarDivider: 1px solid #d8dae2;
$calendarMultipleMonthDivider: 1px solid #d8dae2;

//spinner
$spinnerButtonWidth: 1.429em;

//input switch
$inputSwitchWidth: 2.429em;
$inputSwitchHeight: 1em;
$inputSwitchBorderRadius: 8px;
$inputSwitchTransition: background-color $transitionDuration, box-shadow $transitionDuration;
$inputSwitchSliderOffBgColor: $ratingCancelIconColor;
$inputSwitchHandleOffBgColor: $dangerButtonBgColor;
$inputSwitchSliderOffHoverBgColor: $ratingCancelHoverIconColor;
$inputSwitchSliderOnBgColor: #a2e38a;
$inputSwitchHandleOnBgColor: #62cf3a;
$inputSwitchSliderOnHoverBgColor: $accentPrimaryLighter;
$inputSwitchHandleOffFocusBgColor: $borderPrimary;

//panel common (e.g. accordion, panel, tabview)
$panelHeaderBorder: $containerHeaderBorder;
$panelHeaderBgColor: $containerHeaderBgColor;
$panelHeaderTextColor: $containerHeaderTextColor;
$panelHeaderIconColor: $containerHeaderIconColor;
$panelHeaderIconHoverColor: $containerHeaderIconHoverColor;
$panelHeaderIconTransition: color $transitionDuration, box-shadow $transitionDuration;
$panelHeaderFontWeight: 700;
$panelHeaderPadding: 0.857em 1em;
$panelContentBorder: 1px solid $borderPrimaryLight;
$panelContentBgColor: $backgroundPrimaryLighter;
$panelContentTextColor: $textColor;
$panelContentPadding: 0.571em 1em;
$panelFooterBorder: 1px solid $borderPrimaryLight;
$panelFooterBgColor: #ffffff;
$panelFooterTextColor: $textColor;
$panelFooterPadding: 0.571em 1em;
$clickablePanelHeaderBorder: $clickableContainerHeaderBorder;
$clickablePanelHeaderBgColor: $clickableContainerHeaderBgColor;
$clickablePanelHeaderTextColor: $clickableContainerHeaderTextColor;
$clickablePanelHeaderIconColor: $clickableContainerHeaderIconColor;
$clickablePanelHeaderIconTransition: color $transitionDuration;
$clickablePanelHeaderFontWeight: 700;
$clickablePanelHeaderPadding: 0.857em 1em;
$clickablePanelHeaderHoverBgColor: $clickableContainerHeaderHoverBgColor;
$clickablePanelHeaderHoverBorder: $clickableContainerHeaderHoverBorder;
$clickablePanelHeaderHoverTextColor: $clickableContainerHeaderHoverTextColor;
$clickablePanelHeaderHoverIconColor: $clickableContainerHeaderHoverIconColor;
$clickablePanelHeaderActiveBgColor: $clickableContainerHeaderActiveBgColor;
$clickablePanelHeaderActiveBorder: $clickableContainerHeaderActiveBorder;
$clickablePanelHeaderActiveTextColor: $clickableContainerHeaderActiveTextColor;
$clickablePanelHeaderActiveIconColor: $clickableContainerHeaderActiveIconColor;
$clickablePanelHeaderActiveHoverBgColor: $clickableContainerHeaderActiveHoverBgColor;
$clickablePanelHeaderActiveHoverBorder: $clickableContainerHeaderActiveHoverHoverBorder;
$clickablePanelHeaderActiveHoverTextColor: $clickableContainerHeaderActiveHoverTextColor;
$clickablePanelHeaderActiveHoverIconColor: $clickableContainerHeaderActiveHoverIconColor;
$clickablePanelHeaderTransition: background-color $transitionDuration;

//accordion
$accordionSpacing: 2px;

//tabview
$tabsNavBorder: 0 none;
$tabsNavBgColor: #ffffff;
$tabHeaderSpacing: 0.214em;

//scrollpanel
$scrollPanelHandleBgColor: $borderPrimary; //#bdbdbd;
$scrollPanelTrackBorder: 0 none;
$scrollPanelTrackBgColor: $borderPrimaryLight; //#e0e0e0;

//card
$cardShadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

//paginator
$paginatorBgColor: $backgroundPrimaryLighter;
$paginatorBorder: none;
$paginatorPadding: 0;
$paginatorIconColor: $textSecondaryColor;
$paginatorElementWidth: 2.286em;
$paginatorElementHeight: 2.286em;
$paginatorElementHoverBgColor: $accentPrimary;
$paginatorElementHoverIconColor: white;
$paginatorElementBorderRadius: 0;
$paginatorElementMargin: 0;
$paginatorElementPadding: 0;
$paginatorElementBorder: 0 none;

//table
$tableHeaderCellPadding: 0.5em 1.214em;
$tableHeaderCellBgColor: #ffffff;
$tableHeaderCellTextColor: $textColor;
$tableHeaderCellFontWeight: 700;
$tableHeaderCellBorder: $panelContentBorder;
$tableHeaderCellHoverBgColor: $primaryLighterColor;
$tableHeaderCellHoverTextColor: $primaryDarkerColor;
$tableHeaderCellIconColor: $textColor;
$tableHeaderCellHoverIconColor: $primaryDarkerColor;
$tableBodyRowBgColor: #f5f5f5;
$tableBodyRowTextColor: $textColor;
$tableBodyRowEvenBgColor: #ffffff;
$tableBodyRowHoverBgColor: $primaryLighterColor;
$tableBodyRowHoverTextColor: $primaryDarkerColor;
$tableBodyCellBorder: $panelContentBorder;
$tableBodyCellPadding: 0.5em 1.214em;
$tableFooterCellPadding: 0.5em 1.214em;
$tableFooterCellBgColor: #ffffff;
$tableFooterCellTextColor: $textColor;
$tableFooterCellFontWeight: 500;
$tableFooterCellBorder: none;
$tableResizerHelperBgColor: $primaryColor;

//schedule
$scheduleEventBgColor: $primaryColor;
$scheduleEventBorder: 1px solid $primaryDarkColor;
$scheduleEventTextColor: $primaryTextColor;

//tree
$treeContainerPadding: 0.286em; //?
$treeNodePadding: 0.143em 0;
$treeNodeLabelPadding: 0.286em;
$treeNodeContentSpacing: 0.143em;

//lightbox
$lightBoxNavIconFontSize: 2em;
$lightBoxNavIconColor: #ffffff;

//org chart
$organizationChartConnectorColor: #c8c8c8;

//messages
$messagesMargin: 1em 0;
$messagesPadding: 1em;
$messagesIconFontSize: 1.714em;
$messageCloseIconFontSize: 1.5em;

//message
$messagePadding: $inputPadding;
$messageMargin: 0;
$messageIconFontSize: 1.25em; //?
$messageTextFontSize: 1em;

//toast
$toastShadow: 0 3px 0.429em 0 rgba(0, 0, 0, 0.16);
$toastMessageMargin: 0 0 1em 0;

//severities
$infoMessageBgColor: #64b5f6;
$infoMessageBorder: 0 none;
$infoMessageTextColor: #ffffff;
$infoMessageIconColor: #ffffff;

$successMessageBgColor: #4caf50;
$successMessageBorder: 0 none;
$successMessageTextColor: #ffffff;
$successMessageIconColor: #ffffff;

$warnMessageBgColor: #a76502;
$warnMessageBorder: 0 none;
$warnMessageTextColor: #ffffff;
$warnMessageIconColor: #ffffff;

$errorMessageBgColor: #ef5350;
$errorMessageBorder: 0 none;
$errorMessageTextColor: #ffffff;
$errorMessageIconColor: #ffffff;

//toast
$toastTopLocation: 120px;
$toastIconFontSize: 3.429em;
$toastMessageTextMargin: 0 0 0 1em;
$toastMargin: 0 0 1em 0;
$toastPadding: 1em;
$toastShadow: 0 3px 0.429em 0 rgba(0, 0, 0, 0.16);
$toastOpacity: 0.9;
$toastBorderRadius: 3px;

//overlays
$overlayContentBorder: $panelContentBorder;
$overlayContainerShadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

//dialog
$dialogHeaderPadding: 0.5em;
$confirmDialogPadding: 1.5em;

//overlay panel
$overlayPanelCloseIconBgColor: $primaryDarkColor;
$overlayPanelCloseIconColor: $primaryTextColor;
$overlayPanelCloseIconHoverBgColor: $primaryDarkerColor;
$overlayPanelCloseIconHoverColor: $primaryTextColor;

//tooltip
$tooltipBgColor: #212121;
$tooltipTextColor: #ffffff;
$tooltipPadding: $inputPadding;

//steps
$stepsItemBgColor: #ffffff;
$stepsItemBorder: 1px solid #e0e0e0;
$stepsItemBorderRadius: $borderRadius;
$stepsItemNumberColor: $textColor;
$stepsItemTextColor: $textSecondaryColor;
$stepsItemWidth: 2em;
$stepsItemHeight: 2em;

//progressbar
$progressBarHeight: 1.714em;
$progressBarBorder: 0 none;
$progressBarBgColor: #e0e0e0;
$progressBarValueBgColor: $primaryColor;

//menu (e.g. menu, menubar, tieredmenu)
$menuBgColor: $panelContentBgColor;
$menuBorder: $panelContentBorder;
$menuPadding: 0;
$menuTextColor: $textInverseColor;
$menuitemPadding: 0.571em 0.857em;
$menuitemMargin: 0;
$menuitemTextColor: rgba($menuTextColor, 0.85);
$menuitemIconColor: rgba($menuTextColor, 0.85);
$menuitemHoverTextColor: $menuitemTextColor;
$menuitemHoverIconColor: $menuitemTextColor;
$menuitemHoverBgColor: rgba($menuTextColor, 0.1);
$menuitemActiveTextColor: $menuitemTextColor;
$menuitemActiveIconColor: $menuitemTextColor;
$menuitemActiveBgColor: rgba($menuTextColor, 0.2);
$submenuHeaderMargin: 0;
$submenuPadding: 0;
$overlayMenuBorder: 1px solid #e0e0e0;
$overlayMenuShadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

//misc
$maskBgColor: $backgroundMask; //dialog mask
$inlineSpacing: 0.429em; //spacing between inline elements
$chipsItemMargin: 0 0.286em 0 0; //autocomplete and chips token spacing
$dataIconColor: $textSecondaryColor; //icon color of a data such as treetoggler, table expander
$dataIconHoverColor: $textColor; //hover icon color of a data such as treetoggler, table expander

//general
$disabledOpacity: 0.5; //opacity of a disabled item
