// Fund Manager colors - Entities - To color-code icons, and backgrounds of specific entities
// Normal color, OnDark color (specified as rgb triplets)
$entities-colors: (
    generic: (
        var(--entityGeneric),
        var(--entityGenericOnDark)
    ),
    asset: (
        var(--entityAsset),
        var(--entityAssetOnDark)
    ),
    analytics: (
        var(--entityAnalytics),
        var(--entityAnalyticsOnDark)
    ),
    cashflow: (
        var(--entityCashFlow),
        var(--entityCashFlowOnDark)
    ),
    contact: (
        var(--entityContact),
        var(--entityContactOnDark)
    ),
    debt: (
        var(--entityDebt),
        var(--entityDebtOnDark)
    ),
    document: (
        var(--entityDocument),
        var(--entityDocumentOnDark)
    ),
    entities: (
        var(--entityEntities),
        var(--entityEntitiesOnDark)
    ),
    finance: (
        var(--entityFinance),
        var(--entityFinanceOnDark)
    ),
    legal: (
        var(--entityLegal),
        var(--entityLegalOnDark)
    ),
    portfolio: (
        var(--entityPortfolio),
        var(--entityPortfolioOnDark)
    ),
    project: (
        var(--entityProject),
        var(--entityProjectOnDark)
    ),
    proposal: (
        var(--entityProposal),
        var(--entityProposalOnDark)
    ),
    regulatory: (
        var(--entityRegulatory),
        var(--entityRegulatoryOnDark)
    ),
    services: (
        var(--entityService),
        var(--entityServiceOnDark)
    ),
    system: (
        var(--entitySystem),
        var(--entitySystemOnDark)
    ),
    task: (
        var(--entityTask),
        var(--entityTaskOnDark)
    ),
    /* Other non entities classes */
    credit: (
        var(--statusPositive),
        var(--statusPositiveLighterBg)
    ),
    debit: (
        var(--statusNegative),
        var(--statusNegativeLighterBg)
    ),
    management: (
        var(--entityManagement),
        var(--entityManagementOnDark)
    ),
);

// Generate classes from entity definitions
@each $name, $prop in $entities-colors {
    .ent-#{$name}-color {
        color: nth($prop, 1);
    }
    .ent-#{$name}-background-color {
        background-color: nth($prop, 1);
    }
    .layout-menu li.ent-#{$name} > a {
        color: rgba(nth($prop, 2), 1) !important;
        &:hover {
            background-color: rgba(nth($prop, 2), 0.1) !important;
            border-radius: 0px !important;
            // .layout-menuitem-text {
            //     color: rgba(nth($prop, 2), 1) !important;
            // }
        }
        &.active-route {
            background-color: rgba(nth($prop, 2), 0.2);
            box-shadow: inset 2px 0px 0px 0px rgba(nth($prop, 2), 1);
            // .layout-menuitem-text {
            //     color: rgba(nth($prop, 2), 1) !important;
            // }
        }
    }
}
