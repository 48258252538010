@import "variables";
@import "colors";

#root {
	overflow: hidden;
	font-family: $fontFamily;
	color: $foregroundPrimary;
}

// override browser user agent stylesheet
:focus {
	outline-color: $accentPrimary;
}

body {
	h1,
	h2,
	h3,
	h4 {
		color: unset; // Undo Antd global styles that are leaking (from awesome querybuilder)
		margin-bottom: 0.5rem;
	}
	.h1,
	h1 {
		font-size: 2.1rem;
	}
	h3 {
		color: $foregroundPrimaryLight;
		font-size: 18px;
	}
	b,
	strong {
		font-weight: 600;
	}
	ol,
	ul,
	dl {
		margin: 0px;
	}
	dt {
		margin-top: $panelsPadding;
		font-weight: 400;
		color: $foregroundPrimaryLight;
	}
	dd {
		margin-bottom: 0px;
		word-break: break-word;
	}
	a:hover {
		text-decoration: underline;
	}
	.text-label {
		color: $foregroundPrimaryLight;
	}
	.text-small {
		font-size: 12px;
		color: $foregroundPrimaryLight;
	}
	.text-small-colorless {
		font-size: $fontSize;
	}
	.text-left {
		text-align: left !important;
	}
	.text-right {
		text-align: right !important;
	}
	.text-center {
		text-align: center !important;
	}
	.text-nowrap {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	.currency {
		font-family: "Noto Mono";
	}
	.page-header {
		background-color: $backgroundPrimaryLighter;
		margin: -$panelsPadding;
		margin-bottom: $panelsPadding;
		padding: $panelsPadding $panelsPadding 0px $panelsPadding;
	}
	.page-header-slim {
		background-color: $backgroundPrimaryLighter;
		margin: -$panelsPadding;
		margin-bottom: $panelsPadding;
		padding: 0px $panelsPadding 0px $panelsPadding;
		min-height: 50px;
		display: flex;
		align-items: flex-end;
	}
	form,
	.gx-form-body {
		label {
			padding: $panelsPadding 0px 5px 0px;
			margin: 0px;
		}
		input {
			height: $inputHeight;
		}
	}
}

/* Buttons - Backgroundless */
.p-toolbar .p-button.button-backgroundless {
	margin-right: $panelsPadding;
}
.p-toolbar-group-right .p-button.button-backgroundless {
	margin-left: $panelsPadding;
	margin-right: 0px;
}
.p-button.button-backgroundless {
	@extend %button-backgroundless;
}

%button-backgroundless {
	background: none;
	border: 0px;
	&:not(.p-disabled) {
		color: $textColor;
		span.far {
			color: $textSecondaryColor;
		}
	}
	&.p-disabled {
		color: $foregroundPrimaryLighter !important;
		opacity: 1;
	}
	.p-button-icon-left {
		left: 0px !important;
	}
	.p-button-text {
		padding-left: 22px !important;
		padding-right: 0px !important;
	}
	&:enabled:hover,
	&:focus {
		background: none;
		color: $primaryColor;
		span.far {
			color: $primaryColor;
		}
	}
	&:focus {
		border: 0px !important;
		box-shadow: none !important;
	}
	&:active:not(.p-disabled) {
		background: none;
		color: $textSecondaryColor;
		span.far {
			color: $textSecondaryColor;
		}
	}
}

body .p-selectbutton .p-button.p-focus {
	border-color: $inputFocusBorderColor;
}
.p-button.button-link {
	@extend .p-button.button-backgroundless;
	text-align: left;
	&:not(.p-disabled) {
		color: $primaryColor;
	}
	&:enabled:hover {
		text-decoration: underline !important;
	}
	.p-button-text {
		padding-left: 0px !important;
	}
}

.p-button.button-help {
	@extend .p-button.button-backgroundless;
	margin-right: 10px;
	.p-button-label, .p-button-icon-left {
		color: $primaryColor !important;
	}
	&:enabled:hover {
		background: $backgroundPrimaryLight;
	}

	.p-button-icon-left{
		padding-left: 6px !important;
	}
	
}

body {
	.p-inputtext {
		border: $borderDark;
		width: 100%;
	}
	/* e.g. Calendar Button */
	.p-button.p-button-icon-only:not(.p-splitbutton-menubutton) {
		background: unset;
		color: $primaryColor;
		width: unset;
		&.p-disabled {
			color: $accentPrimaryLight;
		}
		.p-button-text {
			display: none;
		}
	}
	/* Split Button */
	.p-splitbutton
	{
		white-space: nowrap;
		.p-button:focus {
			box-shadow: none;
		}
		.p-button-text-icon-left .p-button-icon-left {
			padding-left: 4px;
		}
		.p-menu {
			.p-menuitem-link {
				padding-left: 10px;
			}
			.p-menuitem-text {
				padding-left: 2px;
			}
		}
	}

	.p-confirm-dialog-icon {
        font-size: 2em;
    }
}

/* Icon filters */
.icon-blur-duotone {
	&::before {
		filter: drop-shadow(0px 1px 4px);
	}
}
