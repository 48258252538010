@import "src/layout/sass/_mixins";
@import "src/layout/sass/_colors";

$chipTypes: (
	primary: (
		$chipPrimaryBgColor
	),
	secondary: (
		$chipSecondaryBgColor
	),
	neutral: (
		$chipNeutralBgColor
	),
	delegation: (
		$chipDelegationBgColor
	)
);

/* .gx-chip-<chipType> */
@each $name, $prop in $chipTypes {
	.gx-chip-#{$name} {
		margin: 2px 4px;
    	padding: 0 8px;
		border-radius: 8px;
		white-space: nowrap;
		
		color: $foregroundOverAccent;
		background: nth($prop, 1);

		i {
			margin-right: 4px;
		}
	}
}
