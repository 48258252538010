.c-richtext-editor {
	padding: 1px;
	/*
	 1px padding, to overcome negative 1px margin in toolbar,
	 which in turn accommodates outline focus in textarea.
	 */

	& .p-editor-toolbar.ql-toolbar.ql-snow {
		background-color: #eee;
		border-radius: 0;
		border: 1px solid #888;
		border-bottom: 0;
		margin: -1px;
		padding: 2px 0 4px 0 !important; // sides: 0, top: 2px, bottom: 4px to offset outline from editor below.
	}

	& .ql-toolbar.ql-snow + .ql-container.ql-snow {
		border: 0;
		border-radius: 0;
		padding: 0;

		& .ql-editor {
			padding: .5em;
		}
	}

	& .ql-toolbar.ql-snow + .ql-container.ql-snow .ql-editor {
		border: 2px solid transparent !important;
		outline: 1px solid #888 !important;
		border-radius: 0;
		padding: .5em;
		transition: all ease-in-out .2s;

		&::before {
			left: .5em;
			right: .5em;
			padding: 0;
			margin: 0;
			transition: all ease-in-out .2s;
		}

		&:focus {
			border: 2px solid $accentPrimaryLight !important;
			outline: 0;
			border-radius: 0;

			&::before {
				color: #ccc;
			}
		}
	}
}
