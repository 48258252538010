@import "src/layout/sass/fonts";

.layout-wrapper {
    .layout-menu-container {
        position: fixed;
        height: 100%;
        z-index: 900;
        top: 0!important;
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
            url("../../../layout/images/fund-manager/bgMenu.jpg") no-repeat;
        border-right: 1px solid $borderPrimaryLight;
        background-size: cover;

        .layout-menu-wrapper {
            position: relative;
            width: 100%;
            height: 100%;

            .layout-menu-header {
                font-family: $fontFamily;
                height: 60px;
                padding: $panelsPadding 0px 0px 60px;
                font-size: 20px;
                font-weight: 300; // Light
                color: rgba(255,255,255,.85);
            }
        }
        
        .line-break {
            display: block; 
            margin-left: 20px;
            margin-right: 0px;
            margin-bottom: 0;
            overflow: hidden; 
            border: solid 0.1px;
            color: rgba(255,255,255, 0.2);
        }

        .layout-menu-button {
            position: absolute;
            z-index: 999;
            right: 24px;
            display: block;
            text-align: left;
            height: 60px;
            width: initial;
            padding-left: 12px;
            font-size: 20px;
            color: $menuitemTextColor;

            img {
                vertical-align: middle;
                height: 30px;
            }

            &:focus {
                @include shadow(0 0 0 0 $logoBgColor);
            }
    
            &.pinned {
                > i {
                    @include transition(transform .2s ease-in-out);
                    @include rotate(0);
                }
            }
            &.unpinned {
                > i {
                    @include transition(transform .2s ease-in-out);
                    @include rotate(45deg);
                }
            }
        }
        
        // Compact submenu
        .layout-menu-slim {
            margin-top: 58px;
            i {
                font-size: 18px;
            }
            ul.layout-menu ul {
                background-color: $menuBgColorLight;
                border-left: none !important;
            }
        }

        .layout-menu-image {
			position: absolute;
			width: 250px;
            height: 200px;
            border-right: 1px solid $borderPrimaryLight;
			bottom: 0;
			background-image: linear-gradient(
                    180deg,
                    $menuBgColor 15%,
                    rgba($menuBgColor, 0.7) 50%,
                    rgba($menuBgColor, 0.45) 99%,
                    $menuBgColor 100%
                ),
                url("../../../layout/images/fund-manager/bgMenu.jpg");
			background-size: 250px auto;
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);
		}

        .layout-profile {
            border-bottom: $menuSeparator;

            &.layout-profile-active {
                .layout-profile-icon {
                    @include rotate(-180deg);
                }
                .layout-profile-menu {
                    max-height: 450px;
                }
            }

            .layout-profile-button {
                padding: 20px 12px;
                display: block;
                position: relative;
                width: 100%;
                @include transition(background-color $transitionDuration);
                @include clearfix();

                img {
                    width: 36px;
                    display: inline-block;
                }

                .layout-profile-userinfo {
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 8px;

                    .layout-profile-name {
                        display: inline-block;
                        font-weight: 700;
                        color: $menuitemTextColor;
                    }
    
                    .layout-profile-role {
                        display: block;
                        color: $menuitemTextColor;
                    }
                }

                .layout-profile-icon {
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    margin-top: -8px;
                    font-size: 16px;
                    @include transition(transform $transitionDuration);
                    color: $menuitemIconColor;
                }
                
                &:hover {
                    background-color: $menuitemHoverBgColor;
                }

                &:focus {
                    @include shadow(0 0 0 0 $menuitemHoverBgColor);
                }
            }

            .layout-profile-menu {
                list-style: none;
                padding: 0 12px;
                margin: 0;
                overflow: hidden;
                max-height: 0;
                -moz-transition-duration: 0.4s;
                -webkit-transition-duration: 0.4s;
                -o-transition-duration: 0.4s;
                transition-duration: 0.4s;
                -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
                -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
                -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
                transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
                
                li {
                    button {
                        display: block;
                        padding: 8px 4px;
                        text-align: left;
                        width: 100%;
                        @include border-radius($borderRadius);
                        @include transition(background-color $transitionDuration);
                        
                        i {
                            margin-right: 8px;
                            vertical-align: middle;
                        }
                        
                        span {
                            font-weight: 700;
                            display: inline-block;
                            vertical-align: middle;
                        }

                        &:focus {
                            @include shadow(0 0 0 0 $menuitemHoverBgColor);
                        }
                    }
                }
            }
        }

        .layout-menu {
            list-style-type: none;
            padding: $panelsPadding 12px 0 0;
            margin: 0;
            height: 100%;

            .layout-root-menuitem {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.65)!important;

                ul {
                    > li {
                        font-size: $fontSize;
                        color: rgba(255, 255, 255, 0.85);

                        ul {
                            max-height: 0;
                            overflow: hidden;
                            -moz-transition-duration: 0.4s;
                            -webkit-transition-duration: 0.4s;
                            -o-transition-duration: 0.4s;
                            transition-duration: 0.4s;
                            -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
                            -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
                            -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
                            transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);

                            > li {
                                margin-left: $panelsPadding;

                                a {
                                    &:hover {
                                        @include border-radius($borderRadius);
                                    }
                                }
                            }
                        }
                        &.active-menuitem {
                            > ul {
                                max-height: 1000px;
                            }
                        }
                    }
                }
            }

            li {
                a {
                    display: block;
                    padding: 8px 0 8px 16px;
                    position: relative;
                    cursor: pointer;
                    @include border-radius-right($borderRadius);
                    @include transition(background-color $transitionDuration);

                    &:focus {
                        outline: 0 none;
                    }

                    .layout-menuitem-icon {
                        margin-right: 6px;
                        vertical-align: middle;
                        color: $menuitemIconColor;
                    }

                    .layout-menuitem-text {
                        font-weight: $fontWeightSemiBold !important;
		                vertical-align: unset !important;
                    }

                    .layout-submenu-toggler {
                        position: absolute;
                        right: 0px;
                        top: 50%;
                        font-size: 16px;
                        margin-top: -8px;
                        @include transition(transform $transitionDuration);
                        color: $menuitemIconColor!important;
                        
                        &:before {
                            content: "\f078";
                        }
                    }

                    .menuitem-badge {
                        position: absolute;
                        display: block;
                        right: 24px;
                        height: 20px;
                        width: 20px;
                        line-height: 20px;
                        top: 50%;
                        font-size: 12px;
                        margin-top: -10px;
                        font-weight: 700;
                        background-color: $badgeBgColor;
                        color: $badgeTextColor;
                        text-align: center;
                        @include border-radius(50%);
                    }
                    
                    &:hover {
                        color: $menuitemHoverBgColor;
                        text-decoration: none;
                    }

                    &.active-route {
                        padding-left: 16px;
                    }
                }

                &.active-menuitem {
                    > a {
                        .layout-submenu-toggler {
                            @include rotate(-180deg);
                        }
                    }
                }

                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                    li {
                        li {
                            a {
                                padding-left: 16px;
                            }

                            li {
                                a {
                                    padding-left: 32px;
                                }

                                li {
                                    a {
                                        padding-left: 48px;
                                    }

                                    li {
                                        a {
                                            padding-left: 64px;
                                        }

                                        li {
                                            a {
                                                padding-left: 80px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        ul {
                            overflow: hidden;
                        }
                    }
                }
            }

            > li {
                margin-bottom: 16px;

                > a {
                    display: none;
                }

                > div {
                    margin-bottom: 4px;
                    padding-left: 12px;
                }
            }

            .layout-menu-tooltip {
                display:none;
                padding: 0 5px;
                position: absolute;
                left: 61px;
                top: 17px;
                line-height: 1;
                
                .layout-menu-tooltip-text {
                   padding: 6px 8px;
                   font-weight: 700;
                   background-color: $tooltipBgColor;
                   color: $tooltipTextColor;
                   min-width: 75px;
                   white-space: nowrap;
                   text-align: center;
                   @include border-radius($borderRadius);
                   @include shadow(0 2px 10px 0 rgba(0, 3, 6, 0.16));
                }
                
                .layout-menu-tooltip-arrow {
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-color: transparent;
                    border-style: solid;
                    top: 50%;
                    left: 0;
                    margin-top: -5px;
                    border-width: 5px 5px 5px 0;
                    border-right-color: $tooltipBgColor;
                }
            }
        }

        .p-scrollpanel {
            background-color: transparent;
            border-radius: 0;
            border: none;

            .p-scrollpanel-content {
                overflow-x: hidden;
            }

            .p-scrollpanel-bar {
                background-color: #aaaaaa;
                opacity: 0.3;
                filter: alpha(opacity=30);
            }

            .p-scrollpanel-hidden {
                display: block;
                visibility: hidden;
            }

            .p-scrollpanel-bar-x {
                display: none;
            }

            .menu-scroll-content {
                width: 268px;
                padding-right: 18px;
                padding-bottom: 120px;
            }
        }
    }

    .layout-main {
        padding-top: 60px;

        .layout-content {
            padding: 16px;
        }
    }
}

@media screen and (min-width: 897px) {
    .layout-wrapper {
        .layout-menu-container {
            .layout-profile-menu {
                background-color: $menuBgColor;
            }
        }

        &.layout-static {
            .layout-menu-container {
                width: 250px;
                left: 0;                
                @include transition(left $transitionDuration);
    
                .layout-menu-header {
                    margin-left: 0 !important;
                }
                .layout-menu-image {
                    @include transition(left $transitionDuration);
                    left: 0 !important;
                }
            }

            .layout-topbar {
                @include transition(margin-left $transitionDuration);
            }
    
            .layout-main {
                margin-left: 250px;
                @include transition(margin-left $transitionDuration);
            }

            .layout-footer {
                padding-left: 270px;
            }
    
            &.layout-static-inactive {
                .layout-topbar {
                    margin-left: 250px;
                    top: 0;
                    @include transition(left $transitionDuration);
                }

                .layout-menu-container {
                    left: -250px;
                    
                    .layout-menu-header {
                        margin-left: -250px !important;
                    }
                    .layout-menu-image {
                        @include transition(left $transitionDuration);
                        left: -250px !important;
                    }
                }
    
                .layout-main {
                    margin-left: 0;
                }

                .layout-footer {
                    padding-left: 20px;
                }
            }

            &.layout-static-inactive-restore {
                .layout-menu-container {
                    @include transition(none);
                }
            }
        }
    
        &.layout-overlay {
            .layout-topbar {
                margin-left: 0;
                @include transition(margin-left $transitionDuration);
            }

            .layout-menu-container {
                width: 250px;
                left: -250px;
                @include shadow(0 2px 10px 0 rgba(0, 3, 6, 0.16));
                @include transition(left $transitionDuration);

                .layout-menu, .layout-profile {
                    border-right: 0 none;
                }
                
                .layout-menu-header {
                    margin-left: -250px !important;
                }
                .layout-menu-image {
                    @include transition(left $transitionDuration);
                    left: -250px !important;
                }
            }
    
            &.layout-overlay-active {
                .layout-topbar {
                    margin-left: 0;
                }

                .layout-menu-container {
                    left: 0;
                    
                    .layout-menu-header {
                        margin-left: 0 !important;
                    }
                    .layout-menu-image {
                        @include transition(left $transitionDuration);
                        left: 0 !important;
                    }
                }
            }
        }

        &.layout-slim {
            .layout-topbar {
                margin-left: 60px;
            }

            .layout-main {
                margin-left: 60px;
            }

            .layout-menu-container {
                padding: 0;
                height: 100%;
                width: 60px;
                overflow: visible;
                position: fixed;

                .p-scrollpanel {
                    .p-scrollpanel-wrapper {
                        overflow: visible;
                        .p-scrollpanel-content {
                            overflow: visible;
                        }
                    }

                    .p-scrollpanel-bar {
                        display: none;
                    }

                    .menu-scroll-content {
                        width: 100%;
                        padding: 0;
                    }
                }

                .layout-profile {
                    .layout-profile-button {
                        text-align: center;
                        padding: 12px 0;

                        img {
                            width: 32px;
                        }

                        .layout-profile-userinfo,
                        .layout-profile-icon {
                            display: none;
                        }
                    }

                    .layout-profile-menu {
                        position: absolute;
                        overflow: auto;
                        top: 0;
                        left: 59px;
                        min-width: 250px;
                        max-height: 450px;
                        display: none;
                        border-left: 4px solid $topbarBgColor;
                        padding: 8px 12px;
                        @include border-radius-right($borderRadius);
                        @include shadow(0 2px 10px 0 rgba(0, 3, 6, 0.16));

                        &:before, &:after {
                            bottom: 100%;
                            left: 50%;
                            border: solid transparent;
                            content: " ";
                            height: 0;
                            width: 0;
                            position: absolute;
                            pointer-events: none;
                            left: 210px;
                        }
    
                        &:before {
                            border-color: rgba(216, 216, 220, 0);
                            border-bottom-color: $topbarMenuBorderColor;
                            border-width: 10px;
                            margin-left: -10px;
                        }
    
                        &:after {
                            border-color: rgba(244, 244, 249, 0);
                            border-bottom-color: $topbarMenuBgColor;
                            border-width: 9px;
                            margin-left: -9px;
                        }

                        li {
                            button {
                                padding: 8px 12px;
                                @include transition(background-color $transitionDuration);
                            }
                        }
                    }

                    &.layout-profile-active {
                        .layout-profile-menu {
                            display: block;
                        }
                    }
                }
                
                .layout-menu {
                    padding: 0;

                    li.active-menuitem {
                        > ul {
                            display: block;
                        }
                    }
                    
                    > li {
                        position: relative;
                        margin-bottom: 0;

                        &.active-menuitem {
                            > a {
                                @include border-radius-right(0);
    
                                .layout-menuitem-text, 
                                .layout-menuitem-icon,
                                .layout-submenu-toggler {
                                    color: $horizontalMenuitemActiveTextColor;
                                }
                            }
                        }

                        > div {
                            display: none;
                        }
                                            
                        > a {
                            display: block;
                            text-align: center;
                            padding-left: 0!important;
                            padding-right: 0;
                            padding-top: 19px;
                            padding-bottom: 19px;
                            @include border-radius(0);
                            
                            .layout-menuitem-icon {
                                font-size: 24px;
                                margin-right: 0;
                            }
                            
                            .layout-menuitem-text, .layout-submenu-toggler {
                                display: none;
                            }
                            
                            &:hover + .layout-menu-tooltip {
                                display: block;
                            }
                        }
                        
                        > ul {
                            position: absolute;
                            overflow: auto;
                            top: 0;
                            left: 59px;
                            min-width: 250px;
                            max-height: 450px;
                            display: none;
                            border-left: 2px solid $menuitemHoverBgColor;
                            padding: 8px 12px;
                            @include border-radius-right($borderRadius);
                            @include shadow(0 2px 10px 0 rgba(0, 3, 6, 0.16));
                            
                            li {
                                a {
                                    padding: 8px 12px;
                                    display: block;
                                }

                                ul {
                                    width: 100%;
                                    @include shadow(none);

                                    li {
                                        a {
                                            padding-left: 28px;
                                        }
                
                                        li {
                                            a {
                                                padding-left: 44px;
                                            }
                
                                            li {
                                                a {
                                                    padding-left: 60px;
                                                }
                
                                                li {
                                                    a {
                                                        padding-left: 76px;
                                                    }
                
                                                    li {
                                                        a {
                                                            padding-left: 92px;
                                                        }
                
                                                        li {
                                                            a {
                                                                padding-left: 108px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        
                        &.active-menuitem {
                            > a {                                
                                &:hover + .layout-menu-tooltip {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.layout-slim {
        .layout-menu-container {
            .layout-menu {
                .layout-root-menuitem {
                    font-size: $fontSize;
                }
            }
        }
    }
}

@media screen and (max-width: 896px) {
    .layout-wrapper {
        .layout-topbar {
            margin-left: 0;
        }

        .layout-menu-container {
            width: 0;
            @include transition(all $transitionDuration);

            .layout-menu-button {
                display: none;
            }

            .layout-menu-header {
                margin-left: -250px !important;
            }
            .layout-menu-image { 
                @include transition(left $transitionDuration);
                left: -250px !important;
            }
        }

        .layout-main {
            margin-left: 0;
            @include transition(margin-left $transitionDuration);
        }

        &.layout-mobile-active {
            .layout-menu-container {
                width: 250px;
                
                .layout-menu-header {
                    margin-left: 0 !important;
                }
                .layout-menu-image {
                    @include transition(left $transitionDuration);
                    left: 0 !important;
                }
            }
            .layout-mask {
                display: block;
            }
        }

        .menu-scroll-content {
            width: 100%!important;
            padding: 0!important;
        }

        .menu-sectigo {
            display: none;
        }
    }

    body.blocked-scroll {
        overflow: hidden;
    }
}
