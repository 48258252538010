.layout-wrapper {
    .layout-active-delegation {
        background: $statusDelegation;
        border-bottom: 1px solid $statusDelegationBorder;
        box-shadow: 0px 2px 4px rgba(140, 26, 108, 0.33);
        height: 6px;
        width: 100%;

        &.stripes {
            background-size: 20px 20px;
            background-image: linear-gradient(
                135deg,
                rgba(255, 255, 255, .15) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, .15) 50%,
                rgba(255, 255, 255, .15) 75%,
                transparent 75%,
                transparent
            );
        }

        &.animated {
            animation: animate-stripes 0.3s linear infinite;
            animation-duration: 8s;
            animation-direction: reverse;
        }
    }

    .layout-topbar {
        position: static;
        min-height: 60px;
        top: 0;
        margin-left: 250px;
        padding: 8px;
        padding-bottom: 0px;
        background-color: $backgroundPrimary;
        @include clearfix();

        .layout-menu-button {
            cursor: pointer;
            float: left;
            width: 44px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            color: $topbarIconColor;
            @include border-radius(50%);
            @include transition(background-color $transitionDuration);

            i {
                line-height: inherit;
                font-size: 28px;
            }

            &:hover {
                background-color: $topbarIconHoverBgColor;
            }

            &:focus {
                @include shadow(0 0 0 0 $topbarIconHoverBgColor)
            }
        }

        .topbar-menu {
            right: 4px;
            padding: 0;
            margin: 0;
            list-style-type: none;
            display: flex;
            justify-content: flex-end;

            > li {
                position: relative;
                min-width: 44px;

                > button {
                    cursor: pointer;
                    position: relative;
                    display: block;
                    width: 44px;
                    height: 44px;
                    line-height: 44px;
                    text-align: center;
                    @include border-radius(50%);
                    @include transition(background-color $transitionDuration);
                    
                    .topbar-icon {
                        color: $topbarIconColor;
                        line-height: inherit;
                        font-size: 28px;
                    }

                    > img {
                        width: 36px;
                        margin-top: 4px;
                    }

                    &:hover {
                        background-color: $topbarIconHoverBgColor;
                        // color: $foregroundOverAccent;
                    }

                    &:focus {
                        // @include shadow(0 0 0 0 $topbarIconHoverBgColor);
                        border: none !important;
                        outline: none !important;
                    }
                    
                    .topbar-item-name {
                        display: none;
                    }
                    
                    .topbar-badge {
                        position: absolute;
                        right: 4px;
                        top: 4px;
                        background-color: #e0284f;
                        color: #ffffff;
                        text-align: center;
                        line-height: 16px;
                        min-width: 16px;
                        min-height: 16px;
                        display: block;
                        font-size: 12px;
                        border-radius: 100%;
                    }
                }
            
                > ul {
                    position: absolute;
                    border: 1px solid $topbarMenuBorderColor;
                    top: 55px;
                    right: -8px;
                    display: none;
                    min-width: 250px;
                    background-color: $topbarMenuBgColor;
                    -webkit-animation-duration: $transitionDuration;
                    -moz-animation-duration: $transitionDuration;
                    animation-duration: $transitionDuration;
                    list-style-type: none;
                    margin: 0;
                    padding: $panelsPadding;
                    @include border-radius($borderRadius);
                    @include shadow(0 2px 10px 0 rgba(0, 3, 6, 0.16));

                    &:before, &:after {
                        bottom: 100%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        right: 20px;
                    }

                    &:before {
                        border-color: rgba(216, 216, 220, 0);
                        border-bottom-color: $topbarMenuBorderColor !important;
                        border-width: 10px;
                        margin-left: -10px;
                    }

                    &:after {
                        border-color: rgba(244, 244, 249, 0);
                        border-bottom-color: $topbarMenuBgColor !important;
                        border-width: 9px;
                        margin-left: -9px;
                    }
                    
                    button {
                        box-sizing: border-box;
                        padding: 8px 12px;
                        text-align: left;
                        display: block;
                        width: 100%;
                        color: $textColor;
                        position: relative;
                        @include transition(background-color $transitionDuration);
                        @include border-radius($borderRadius);
                        
                        i {
                            margin-right: 8px;
                            color: $textColor;
                        }
                        
                        img {
                            margin-right: 8px;
                            width: 24px;
                        }
                        
                        i,img,span {
                            vertical-align: middle;
                        }
                        
                        .topbar-submenuitem-badge {
                            background-color: $topbarBadgeBgColor;
                            color: $topbarBadgeTextColor;
                            text-align: center;
                            line-height: 16px;
                            min-width: 16px;
                            height: 16px;
                            font-size: 12px;
                            border-radius: 100%;
                            display: inline-block;
                            margin-left: 8px;
                        }
                        
                        &:focus {
                            @include shadow(0 0 0 0 $topbarMenuitemHoverBgColor)
                        }
                    }
                }
                
                &.active-topmenuitem {
                    > ul {
                        display: block;
                        z-index: 100;
                    }
                }
            }
        }

        &.dark {
            background-size: 100% auto;
        }
    }
}
