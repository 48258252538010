@import '../_colors';
@import '../_variables';

/* Add your variable customizations of theme here */
// $inputFocusBorderColor: $inputFocusBorderColor;

// data table
$tableHeaderCellBgColor: $backgroundPrimaryLighter;
$tableHeaderCellFontWeight: 600;
$tableBodyRowBgColor: $backgroundPrimaryLighter;
$tableBodyRowTextColor: $textColor;
$tableBodyRowEvenBgColor: $backgroundPrimaryLighter;
$tableBodyRowHoverBgColor: $accentPrimaryLighter;
$tableBodyRowHoverTextColor: $textColor;
$tableHeaderCellFontWeight: 600;

// input field (e.g. inputtext, spinner, inputmask)
$inputPadding: 0.429em;
$inputBgColor: $backgroundPrimaryLighter;
$inputBorder: 1px solid $borderPrimary;
$inputHoverBorderColor: $borderPrimary;
$inputFocusBorderColor: $primaryColor;
$inputErrorBorder: 1px solid #a80000;
$inputPlaceholderTextColor: #666666;
$inputTransition: border-color $transitionDuration, box-shadow $transitionDuration;

// clickable panels (e.g. tabmenu)
$panelHeaderBorder: $borderPrimaryLight;
$panelHeaderBgColor: $backgroundPrimaryLighter;
$panelContentBgColor: $backgroundPrimaryLighter;
$clickablePanelHeaderBorder: 0px;
$clickablePanelHeaderBgColor: $backgroundPrimaryLighter;
$clickablePanelHeaderTextColor: $textSecondaryColor;
$clickablePanelHeaderIconColor: $textSecondaryColor;
$clickablePanelHeaderActiveBorder: 0px;
$clickablePanelHeaderActiveBgColor: $backgroundPrimary;
$clickablePanelHeaderActiveTextColor: $primaryColor;
$clickablePanelHeaderActiveIconColor: $primaryColor;
$inputTransition: border-color $transitionDuration, box-shadow $transitionDuration;

/* Cards */
$cardShadow: none;

/* Other */
$buttonIconOnlyWidth: $inputHeight;
$disabledOpacity: $inputDisabledOpacity;
$dangerButtonBgColor: $statusNegative;
$dangerButtonTextColor: $textInverseColor;
$dangerButtonBorder: 1px solid $statusNegative;
