@import "src/layout/sass/media-queries";

.gx-app-message {
    position: absolute!important;
    width: 30%!important;
    top: 10px!important;
    right: 10px!important;
    opacity: 1!important;
    z-index: 9999!important;
}

@include media-breakpoint-below(xl) {
    .gx-app-message {
        width: 50%!important;
    }
}

@include media-breakpoint-below(lg) {
    .gx-app-message {
        width: 75%!important;
    }
}
@include media-breakpoint-below(md) {
    .gx-app-message {
        width: 100%!important;
        top: 0!important;
        right: 0!important;
    }
}
