@import "src/layout/sass/_mixins";
@import "src/layout/sass/_colors";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/variables";

$labelTypes: (
	success: (
		$fa-var-warehouse,
		$statusPositive
	),
	info: (
		$fa-var-balance-scale,
		$statusNeutral
	),
	warning: (
		$fa-var-exclamation-triangle,
		$statusAttention
	),
	error: (
		$fa-var-exclamation-circle,
		$statusNegative
	)
);


@mixin label-content($icon: null, $color: null) {
	color: $color !important;
	font-weight: 400;
	display: block;
    
	@if $icon {
		> i {
			@extend %gx-icon;
			margin-right: 6px;

			&:before {
				content: fa-content($icon);
			}
		}
    }    
}

/* .gx-label.<labelType>-icon e.g. .gx-label.warning-icon  .gx-label.error-icon */
@each $name, $prop in $labelTypes {
	.gx-label.#{$name}-icon {
		@include label-content(nth($prop, 1), nth($prop, 2));
	}
	.gx-label.#{$name}-subtitle {
		color: nth($prop, 2);
		font-weight: 400 !important;
	}
}
