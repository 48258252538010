/* Make the element's opacity pulse */
/* Usage
    .myElement {
        animation: opacityPulse 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 0; 
    }
*/
@keyframes opacityPulse {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes opacityPulseInverse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes opacityFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes opacityFadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@mixin keyframes-bgColorPulse($name, $from, $to) {
    @keyframes #{$name} {
        0% {
            background-color: $from;
        }
        20% {
            background-color: $to;
        }
        80% {
            background-color: $to;
        }
        100% {
            background-color: $from;
        }
    }
}

@include keyframes-bgColorPulse("bgColorPulse-accentSecondaryLight", "transparent", $accentSecondaryLight);

@include keyframes-bgColorPulse("bgColorPulse-accentSecondaryLight-replica", "transparent", $accentSecondaryLight);

// We must alternate css (replica) so that the animation plays again when required
.item-updated {
    animation: bgColorPulse-accentSecondaryLight 3s ease;
    animation-iteration-count: 1;
}

.item-updated-replica {
    animation: bgColorPulse-accentSecondaryLight-replica 3s ease;
    animation-iteration-count: 1;
}

/* React Transition Group Animations */
.transitionCardsExpand-enter {
    opacity: 0;
    transform: scale(0.9);
    .p-sidebar:not(.p-sidebar-active) {
        display: none;
    }
}
.transitionCardsExpand-enter-active {
    opacity: 1;
    transition: opacity 300ms, transform 300ms;
}
.transitionCardsExpand-exit {
    opacity: 1;
    .p-sidebar:not(.p-sidebar-active) {
        display: none;
    }
}
.transitionCardsExpand-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

@keyframes animate-stripes {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 60px 0;
    }
}